import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Fullscreen({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M23.2549 7.49995V2.24695C23.2549 1.84912 23.0968 1.46759 22.8155 1.18629C22.5342 0.904984 22.1527 0.746948 21.7549 0.746948H16.5049"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M16.5049 23.247H21.7549C22.1527 23.247 22.5342 23.089 22.8155 22.8077C23.0968 22.5264 23.2549 22.1448 23.2549 21.747V16.5"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M0.754883 16.5V21.75C0.754883 22.1478 0.912918 22.5294 1.19422 22.8107C1.47553 23.092 1.85706 23.25 2.25488 23.25H7.50488"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M7.50488 0.746948H2.25488C1.85706 0.746948 1.47553 0.904984 1.19422 1.18629C0.912918 1.46759 0.754883 1.84912 0.754883 2.24695V7.49995"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Fullscreen;
